import mitt from 'mitt'
//
window.RAF = mitt()
const startTime = new Date();
function animate() {
    requestAnimationFrame(() => animate())
    //
    const endTime = new Date();
    const delta = endTime - startTime;
    //
    window.RAF.emit('animate',  delta)
}

animate()