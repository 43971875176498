/**
 * @author JuanFuent.es
 * @desc Imprime en consola del browser créditos
 * @param { String } Message
 */
export default class Credits {
    constructor(msg = "Lorem Credits") {
        this.msg = [
            `%c %c ${msg} `,
            'background: #f9c7bb; padding: 5px 0;',
            'background: #FFF6F3; color: #55859a; padding: 5px 5px 5px 5px;'
        ]
        this.craft = [
            "%c %c https://juanfuent.es | Web Craft Developer",
            'background: #FFF6F3; color: #f9c7bb; padding: 5px 0;',
            'font-family: monospace; color: #FFF; background: #55859a; padding: 5px 5px 5px 5px;'
        ]
        this.roll()
    }
    
    roll() {
        console.log.apply(console, this.msg)
        console.log.apply(console, this.craft)
    }
}